export class CheckIn {
  private availableUTMTags: string[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  private currentUrl: URL;
  private storedBackgrounds: Record<string, string> = {};

  constructor() {
    this.currentUrl = new URL(window.location.href);
  }

  private getTagAttribute(tag: HTMLElement, attributeName: string): string {
    return `${tag.getAttribute(attributeName)}`;
  }

  public initFrames() {
    const frames = document.getElementsByTagName('iframe');
    const framesCount = frames.length;
    for (let i = 0; i < framesCount; i++) {
      const frame = frames[i];
      if (frame.hasAttribute('data-checkin-form')) {
        this.initFrame(frame);
      }
    }
  }

  private copyUTMTags(source: URL, dest: URL) {
    source.searchParams.forEach((val, key) => {
      if (this.availableUTMTags.includes(key)) {
        dest.searchParams.append(key, val);
      }
    });
    return dest;
  }

  private initFrame(frame: HTMLIFrameElement) {
    const frameURL = this.copyUTMTags(this.currentUrl, new URL(this.getTagAttribute(frame, 'src')));
    frame.setAttribute('src', frameURL.toString());
    this.storedBackgrounds[this.getTagAttribute(frame, 'id')] = frame.style.background;
  }

  private findScrollableParent(node: HTMLElement | null): HTMLElement | null {
    if (node == null) {
      return null;
    }

    const isScrollableParent = (
      node.style.overflowY === ''
      || node.style.overflow === 'auto'
      || node.style.overflow === 'scroll'
      || node.style.overflowY === 'auto'
      || node.style.overflowY === 'scroll'
    ) && (
      node.scrollHeight > node.clientHeight
      || node.scrollHeight < window.innerHeight
    );
    return isScrollableParent ? node : this.findScrollableParent(node.parentNode as HTMLElement);
  }

  public processForms(message: MessageEvent) {
    if (message.data) {
      const frame = document.getElementById(message.data.formId) as HTMLIFrameElement;
      if (!frame) {
        return;
      }

      if (message.data.frameBg) {
        this.setBackground(frame, message.data.frameBg);
      }
      if (message.data.resetFrameBg) {
        this.resetBackground(frame);
      }
      if (message.data.scrollToFormTop) {
        this.scrollToFormTop(frame);
      }
      if (message.data.height) {
        this.setHeight(frame, message.data.height);
      }
      if (typeof message.data.scrollToTicket === 'number') {
        this.scrollToPosition(frame, message.data.scrollToTicket);
      }
      if (message.data.webHook) {
        this.sendWebHook(message.data.webHook.url, message.data.webHook.method);
      }
    }
  }

  private setBackground(frame: HTMLIFrameElement, value: string) {
    frame.style.background = value;
  }

  private resetBackground(frame: HTMLIFrameElement) {
    frame.style.background = this.storedBackgrounds[this.getTagAttribute(frame, 'id')];
  }

  private scrollToFormTop(frame: HTMLIFrameElement) {
    frame.scrollIntoView({behavior: 'smooth'});
  }

  private setHeight(frame: HTMLIFrameElement, height: number) {
    // @ts-ignore
    frame.height = height;
  }

  private scrollToPosition(frame: HTMLIFrameElement, position: number) {
    const scrollableParent = this.findScrollableParent(frame.parentNode as HTMLElement);
    if (scrollableParent) {
      scrollableParent.scrollTo({
        top: frame.offsetTop - scrollableParent.offsetTop + position,
        behavior: 'smooth'
      });
    }
  }

  public sendWebHook(url: string, method: string) {
    if (!url || !method) {
      return;
    }
    try {
      const newUrl = new URL(url);
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.forEach((val, key) => {
        if (this.availableUTMTags.includes(key)) {
          newUrl.searchParams.append(key, val);
        }
      });
      if (method === 'POST') {
        fetch(newUrl.href, {method}).then((res) => {
          // eslint-disable-next-line
          console.log('web hook sent: ', res);
        }).catch((e) => {
          // eslint-disable-next-line
          console.log('web hook error: ', e.message);
        });
      } else if (method === 'GET') {
        const iframe = document.createElement('iframe');
        iframe.src = newUrl.href;
        iframe.style.position = 'absolute';
        iframe.style.left = '-2000px';
        iframe.style.width = '1px';
        iframe.style.height = '1px';
        document.body.appendChild(iframe);
        iframe.addEventListener('load', () => {
          setTimeout(() => {
            iframe.remove();
          }, 1000);
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('e', e);
    }
  }
}
