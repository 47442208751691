import smoothscroll from 'smoothscroll-polyfill';
import {CheckIn} from './CheckIn';

smoothscroll.polyfill();

if (!(window as any).checkin) {
  (window as any).checkin = new CheckIn();

  if (window.onmessage) {
    const a = window.onmessage;
    window.onmessage = (message) => {
      // @ts-ignore
      a(message);
      (window as any).checkin.processForms(message);
    };
  } else {
    window.onmessage = (message) => (window as any).checkin.processForms(message);
  }

  document.addEventListener('DOMContentLoaded', () => {
    (window as any).checkin.initFrames();
  });
}
